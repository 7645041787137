import React from "react";

import "./fadeInSection.css";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (loaded) return; entries.forEach(entry => {
                if (entry.isIntersecting)
                {
                    setVisible(true);
                    setLoaded(true);
                }
            });
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);

    let scale = props.scale;
    if (scale == null) scale = "1.0";

    let translateY = props.translateY;
    if (translateY == null) translateY = "10vh";

    let translateX = props.translateX;
    if (translateX == null) translateX = "0";

    let transform = props.transform;
    if (transform == null) transform = "";
    else transform = " " + transform;


    let style = {
        transform:
            `translateY(${translateY}) scale(${scale}) translateX(${translateX})` + transform,
    }
    if (isVisible) style = {};


    return (
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
            style={style}
        >
            {props.children}
        </div>
    );
}


export default FadeInSection;
