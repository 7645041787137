import React from "react";
import Card from "../../components/card/Card";
import SolidTextBox from "../../components/solid_text_box/SolidTextBox";
import Carousel from "../../components/carousel/Carousel";

import ImgA from "../../assets/images/1669848640.jpg";
import ImgB from "../../assets/images/1669832722.jpg";
import ImgCar from "../../assets/images/IMG_8759_web.jpg";
import ImgRevenue from "../../assets/images/beach.jpg";
import ImgPhotos from "../../assets/images/IMG_0645.jpg"
import InfoText from "../../components/info_text/InfoText";
import ImgJohn from "../../assets/images/face.jpg";

import Texts from "../../assets/texts/pages/home.json";
import FlatrateTexts from "../../assets/texts/pages/flatrates.json";
import FadeInSection from "../../components/fade_in_section/FadeInSection";
import ContactForm from "../../components/contact_form/ContactForm";
import OptionSwitch from "../../components/option_switch/OptionSwitch";
import MonthlyPackage from "../../components/monthy_package/MonthlyPackage";
import PricesData from "../../assets/packages.json";
import CheckedFeature from "../../components/checked_feature/CheckedFeature";

class Home extends React.Component
{
  render()
  {
    const package1 = <MonthlyPackage content={PricesData} priceName={"monthly"}/>;
    const package2 = <MonthlyPackage content={PricesData} priceName={"yearly"}/>;

    return (
      <div id="Home">
        <div className="section" id="home-showcase">
          <h1 id="home-showcase-title">{Texts.homeShowcaseTitle}</h1>
          <a className={"ghost-button"} href={"/contact"} id={"home-showcase-button"}>{"Kontakt aufnehmen"}</a>
        </div>
        <h1 id={"home-services-title"}>{Texts.servicesTitle}</h1>


        <FadeInSection>
          <InfoText title={FlatrateTexts.flatrateTitle} description={FlatrateTexts.flatrateDescription} titlePosition={"left"}/>
        </FadeInSection>

        <FadeInSection>
          <InfoText title={FlatrateTexts.everythingIncludedTitle} description={FlatrateTexts.everythingIncludedDescription} titlePosition={"right"}/>
        </FadeInSection>

        <FadeInSection>
          <OptionSwitch
            optionA={package1}
            optionB={package2}
            textA={"Monatlich"}
            textB={"Jährlich"}
            offerB={"-15%"}
          />

        </FadeInSection>

        <FadeInSection>
          <ContactForm/>
        </FadeInSection>


        <FadeInSection>
          <div className="section" id="home-revenue">
            <div id={"home-revenue-text"}>
              <h1 id="home-revenue-title">{Texts.increaseRevenueTitle}</h1>
              <p id="home-revenue-description">{Texts.increaseRevenueDescription}</p>
              <a className={"ghost-button"} href={"/contact"} id={"home-revenue-button"}>{"Kontakt aufnehmen"}</a>
            </div>
            <img src={ImgRevenue} alt={"Natürliches Hintergrundbild"} id={"home-revenue-image"}/>

          </div>
        </FadeInSection>

        <FadeInSection>
          <div className="container" id="home-steps">
            <h1 id="home-steps-title">{Texts.stepsTitle}</h1>

            <SolidTextBox number={1}
                          description={Texts.stepsToWebsite[0]}
            />
            <SolidTextBox number={2}
                          description={Texts.stepsToWebsite[1]}
            />
            <SolidTextBox number={3}
                          is_last={true}
                          description={Texts.stepsToWebsite[2]}
            />

          </div>
        </FadeInSection>



          <FadeInSection>
              <div className="section" id="home-revenue">
                  <div id={"home-revenue-text"}>
                      <h1 id="home-revenue-title">{Texts.showProductsTitle}</h1>
                      <p id="home-revenue-description">{Texts.showProductsDescription}</p>
                      <a className={"ghost-button"} href={"/contact"} id={"home-revenue-button"}>{"Kontakt aufnehmen"}</a>
                  </div>
                  <img src={ImgPhotos} alt={"Bild meines Fotografiestudios"} id={"home-revenue-image"}/>

              </div>
          </FadeInSection>

          <FadeInSection>
              <div id={"about-me"} className={"container"}>
                  <img src={ImgJohn} alt={"Bild von mir"}/>
                  <div id={"about-me-texts"}>
                      <div id={"about-me-heading"}>{Texts.aboutMe.name}</div>
                      <div id={"about-me-heading-lastname"}>{Texts.aboutMe.lastname}</div>
                      <p id={"about-me-description"}>{Texts.aboutMe.description}</p>
                  </div>
              </div>
          </FadeInSection>

        <FadeInSection>
          <CheckedFeature
            title={FlatrateTexts.features[0].title}
            description={FlatrateTexts.features[0].description}
          />
        </FadeInSection>
        <FadeInSection>
          <CheckedFeature
            title={FlatrateTexts.features[1].title}
            description={FlatrateTexts.features[1].description}
          />
        </FadeInSection>

        <FadeInSection>
          <ContactForm/>
        </FadeInSection>


        <FadeInSection>
          <div id={"page-flatrate-faq-whatpackage"}>
            <InfoText title={FlatrateTexts.faq.whatpackage.title}
                      description={FlatrateTexts.faq.whatpackage.description}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div id={"page-flatrate-faq-whatsincluded"}>
            <InfoText title={FlatrateTexts.faq.whatsincluded.title}
                      description={FlatrateTexts.faq.whatsincluded.description}
                      titlePosition={"right"}/>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div id={"page-flatrate-faq-additionalcosts"}>
            <InfoText title={FlatrateTexts.faq.additionalcosts.title}
                      description={FlatrateTexts.faq.additionalcosts.description}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>

        <div className={"section"} id={"home-photo-introduction"}>

          <FadeInSection>
            <div id={"home-photo-introduction-text"}>
              <div id="home-photo-introduction-title">
                <h1>{Texts.photoIntroductionText}</h1>
              </div>

              <div id="home-photo-introduction-description">
                <p>{Texts.photoIntroductionDescription}</p>
              </div>
            </div>
          </FadeInSection>

          <FadeInSection>
            <Carousel id="home-photo-introduction-carousel" images={[ImgA, ImgB]}/>
          </FadeInSection>
        </div>

        <FadeInSection scale={"1.2"}>
          <InfoText
            title={Texts.photoInfo01Title}
            description={Texts.photoInfo01Description}
            image={ImgCar}
            position={"left"}
          />
        </FadeInSection>


        <FadeInSection scale={"1.2"}>
          <InfoText
            title={Texts.photoInfo02Title}
            description={Texts.photoInfo02Description}
            image={"https://le-cdn.website-editor.net/390ea7f9202e4a42bf4e55913c6c3ce7/dms3rep/multi/opt/IMG_7250-1920w.JPG"}
            position={"right"}
          />
        </FadeInSection>

        <FadeInSection>
          <ContactForm/>
        </FadeInSection>

      </div>
    );
  }
}

export default Home;
