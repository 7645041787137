import React from "react";

const MonthlyPackage = (props) => {

  const content = props.content;
  const priceName = props.priceName;


  let tables = [];

  let amountOfTiers = content.amountOfTiers;

  for (let i = 0; i<amountOfTiers; i++)
  {
    let title = content.tiers[i];
    let priceTag = content[priceName][i];
    let priceSubtitle = content["priceSubtitle-" + priceName][i]
    let rows = [];

    rows.push(<div className={"packages-title"}>{title}</div>);
    rows.push(<div className={"packages-price-tag"}>{priceTag}</div>);
    rows.push(<div className={"packages-price-subtitle"}>{priceSubtitle}</div>);


    for (let j = 0; j<content.rows.length; j++)
    {
      let rowContent = content.rows[j];

      if (rowContent.excludeOnMobile != null && rowContent.excludeOnMobile) continue;

      let result = rowContent.tiers[i];

      if (result === "NO" && !rowContent.forceOnMobile) continue;

      let toAdd;

      if (result === "YES")
      {
        toAdd = <div className={"packages-row"}>
          <span style={{color: "var(--primary)"}} className="material-icons">done</span>
          {rowContent.title}
        </div>
      }
      else if (result === "NO" || result === "NOSHOW")
      {
        toAdd = <div className={"packages-row packages-row-no"}>
          <span className="material-icons">close</span>
          {rowContent.title}
        </div>
      }
      else
      {
        toAdd = <div className={"packages-row"}>
          {rowContent.title + ": "}
          <div className={"prices-row-mobile-information"}>&nbsp;{result}</div>
        </div>
      }

      rows.push(toAdd)
    }

    tables.push(
      <div className={"packages-table"}>
        {rows}
      </div>
    )

  }


  return (
    <div className={"packages-container"}>
      {tables}
    </div>
  );
}

export default MonthlyPackage;