import React, {useState} from "react";
import "./contactForm.css";

const ContactForm = (props) =>
{
  return(
    <div className={"contact-form"}>
      <a className={"button button-xl"} href={"/contact"}>
        Nehmen Sie Kontakt auf
      </a>
    </div>
  )

}

export default ContactForm;
