import React from "react";

class SolidTextBox extends React.Component
{
    render()
    {
        const num = this.props.number;
        const text = this.props.description;

        const isLast = this.props.is_last;

        let className = "solid-text-box";

        if (isLast)
        {
            className += " solid-text-box-last";
        }

        return(
            <div className={className}>
                <div className={"solid-text-box-number"}>{num}</div>
                <div className={"solid-text-box-text"}>{text}</div>
            </div>
        )
    }
}

export default SolidTextBox;