import React from "react";

class Carousel extends React.Component
{
    state = {
        current:0
    }

    maxImages = 0

    next = () => {

        let current = this.state.current;

        current++;
        if (current >= this.maxImages)
        {
            current = 0;
        }

        //alert("Current " + current + " // max: " + this.maxImages);

        this.setCurrentImage(current)

    }

    previous = () => {
        let current = this.state.current;

        current--;
        if (current < 0)
        {
            current = this.maxImages-1;
        }


        this.setCurrentImage(current)
    }


    render()
    {
        let images = this.props.images;
        const current = this.state.current;

        this.maxImages = images.length;

        return(
            <div className={"carousel"}>

                <img src={images[current]} alt="" className={"carousel-image"}/>
                <div className={"carousel-button-container"}>
                    <button className={"carousel-button carousel-button-left"} onClick={this.previous}>
                        <span className="material-icons">
                            arrow_back_ios
                        </span>
                    </button>
                    <button className={"carousel-button carousel-button-right"} onClick={this.next}>
                        <span className="material-icons">
                            arrow_forward_ios
                        </span>
                    </button>
                </div>


            </div>
        )
    }

    setCurrentImage(n)
    {
        this.setState({current:n})
    }
}

export default Carousel