import ContactForm from "../../components/contact_form/ContactForm";
import Texts from "../../assets/texts/pages/contact.json";
import SoftIconBanner from "../../components/soft_icon_banner/SoftIconBanner";


const PageContact = () =>
{
  return(
    <div id={"page-contact"} className={"container"} style={{padding: "25px"}}>
      <h1 style={{textAlign: "center"}}>{Texts.heading}</h1>
      <p style={{textAlign: "center"}}>{Texts.subheading}</p>

      <SoftIconBanner icon_name={"mail"} text={"contact@johndee-software.de"} link={"mailto:contact@johndee-software.de"}/>
      <SoftIconBanner icon_name={"phone"} text={"(+49) 0172 / 9963590"} link={"tel:01729963590"}/>
    </div>
  )
}

export default PageContact;
