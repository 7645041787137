import React from "react";
import { slide as BurgerMenu } from "react-burger-menu";
import Menu, { SubMenu, Item as MenuItem } from "rc-menu";

import "../../menus.css";

class Sidebar extends React.Component {
  state = {
    openKeys: [],
  };

  onOpenChange = (openKeys) => {
    console.log("onOpenChange", openKeys);
    this.setState({
      openKeys,
    });
  };

  render() {
    return (
      <BurgerMenu {...this.props} id={"burger-menu"}>
        <Menu
          id={"burger-menu-menu"}
          onClick={this.onClick}
          mode="inline"
          onOpenChange={this.onOpenChange}
          openKeys={this.state.openKeys}
        >
          <MenuItem key="1" id={"burger-menu-menu-item"}>
            <a href={"/"}>Home</a>
          </MenuItem>

          <MenuItem key="2" id={"burger-menu-menu-item"}>
            <a href={"/contact"}>Kontakt</a>
          </MenuItem>
          {/*<MenuItem key="4" id={"burger-menu-menu-item"}>Über uns</MenuItem>*/}
        </Menu>
      </BurgerMenu>
    );
  }
}

export default Sidebar;
