import React from "react";

import ImgA from "../../assets/images/1669848640.jpg";
import ImgB from "../../assets/images/IMG_8640.png";
import ImgC from "../../assets/images/IMG_8127.png";

import "./pagePhotos.css";
import InfoText from "../../components/info_text/InfoText";
import {Parallax, ParallaxBanner} from "react-scroll-parallax";

import Texts from "../../assets/texts/pages/photos.json";
import Prices from "../../components/prices/Prices";

import PricesData from "../../assets/pricesPhotos.json" ;
import FadeInSection from "../../components/fade_in_section/FadeInSection";

class PagePhotos extends React.Component
{
    constructor(props) {
        super(props)
        this.state = { mobile: window.matchMedia("(max-width: 999px)").matches };
    }

    componentDidMount() {
        const handler = e => this.setState({mobile: e.matches});
        window.matchMedia("(max-width: 999px)").addEventListener('change', handler);
    }


    imgBG = "https://le-cdn.website-editor.net/390ea7f9202e4a42bf4e55913c6c3ce7/dms3rep/multi/opt/IMG_6519-1920w.JPG"

    render()
    {
        return(
            <div>
                { !this.state.mobile &&
                    <div id={"page-photos-banner"}>
                        <ParallaxBanner
                            layers={[

                                {
                                    image: this.imgBG,
                                    speed: 10
                                },
                                {
                                    children: <h1 id={"page-photos-banner-headline"}>{Texts.banner.title} </h1>,
                                    speed: 25
                                },
                                {
                                    children:
                                        <div id={"page-photos-banner-subtitle-container"}>
                                            <p id={"page-photos-banner-subtitle"}>{Texts.banner.subtitle}</p>
                                        </div>,
                                    speed: 40
                                }
                            ]}
                            style={{ aspectRatio: '4 / 2' }}
                        />
                    </div>
                }
                {   this.state.mobile &&
                    <div id={"page-photos-banner-mobile"}>

                        <img id="page-photos-banner-mobile-image" src={this.imgBG} alt="Image"/>
                        <div id="page-photos-banner-mobile-overlay">
                            <h1>{Texts.banner.title}</h1>
                            <p>{Texts.banner.subtitle}</p>
                        </div>

                    </div>
                }

                <div>
                    <FadeInSection scale={"0.8"}>
                        <InfoText
                            title={Texts.info.title01}
                            description={Texts.info.description01}
                            image={ImgA}
                            titlePosition={"top"}
                            position="right"
                        />
                    </FadeInSection>


                    <FadeInSection scale={"0.8"}>
                        <InfoText
                            title={Texts.info.title02}
                            description={Texts.info.description02}
                            image={ImgB}
                            titlePosition={"top"}
                            position="left"
                        />
                    </FadeInSection>

                    <FadeInSection scale={"0.8"}>
                        <InfoText
                            title={Texts.info.title03}
                            description={Texts.info.description03}
                            image={ImgC}
                            titlePosition={"top"}
                            position="right"
                        />
                    </FadeInSection>

                    <FadeInSection scale={"0.6"}>
                        <Prices content={PricesData}/>
                    </FadeInSection>
                </div>

            </div>
        );
    }
}

export default PagePhotos;
