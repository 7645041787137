import React from "react";

import Texts from "../../assets/texts/pages/websites.json";
import InfoText from "../../components/info_text/InfoText";
import Prices from "../../components/prices/Prices";

import PricesData from "../../assets/prices.json";
import FadeInSection from "../../components/fade_in_section/FadeInSection";
import CheckedFeature from "../../components/checked_feature/CheckedFeature";
import ContactForm from "../../components/contact_form/ContactForm";

import ImgJohnDee from "../../assets/images/johndee.jpg";
import ImgPricingTool from "../../assets/images/pricingTool.jpg";
import ImgIVB from "../../assets/images/innovationsbuehne.png";
import ImgReact from "../../assets/images/reactjs-logo.png"
import ImgWordpress from "../../assets/images/WordPress_blue_logo.svg.png"
import ImgIonos from "../../assets/images/2560px-Ionos-by-1and1-logo.svg.png"
import SkillCircle from "../../components/skill_circle/SkillCircle";

class PageWebsites extends React.Component {
  render() {
    return (
      <div id={"page-websites"}>

        <div className={"section"} id={"page-websites-span"}>
          <h1 id={"page-websites-span-title"}>{Texts.title}</h1>
        </div>

        <FadeInSection>
          <CheckedFeature
            title={Texts.features[0].title}
            description={Texts.features[0].description}
          />
        </FadeInSection>
        <FadeInSection>
          <CheckedFeature
            title={Texts.features[1].title}
            description={Texts.features[1].description}
          />
        </FadeInSection>

        <FadeInSection>
          <div id={"page-websites-skills"}>
            <SkillCircle
              image={ImgReact}
              alt={"React"}
            />
            <SkillCircle
              image={ImgWordpress}
              alt={"Wordpress"}
            />
            <SkillCircle
              image={ImgIonos}
              alt={"IONOS by 1&1"}
            />
          </div>
        </FadeInSection>

        <FadeInSection>
          <InfoText
            title={Texts.portfolio.ivb.title}
            description={Texts.portfolio.ivb.description}
            image={ImgIVB}
            position={"left"}
            href={Texts.portfolio.ivb.link}
          />
        </FadeInSection>

        <FadeInSection>
          <InfoText
            title={Texts.portfolio.pricingTool.title}
            description={Texts.portfolio.pricingTool.description}
            image={ImgPricingTool}
            position={"right"}
            href={Texts.portfolio.pricingTool.link}
          />
        </FadeInSection>

        <FadeInSection>
          <InfoText
            title={Texts.portfolio.johndee.title}
            description={Texts.portfolio.johndee.description}
            image={ImgJohnDee}
            position={"left"}
            href={Texts.portfolio.johndee.link}
          />
        </FadeInSection>

        <FadeInSection>
          <div id={"page-websites-webtech"}>
            <InfoText title={Texts.reactWebsitesTitle} description={Texts.reactWebsitesDescription}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>


        <FadeInSection>
          <div id={"page-websites-webtech"}>
            <InfoText title={Texts.ecommerceWebsitesTitle} description={Texts.ecommerceWebsitesDescription}
                      titlePosition={"right"}/>
          </div>
        </FadeInSection>


        <FadeInSection>
          <div id={"page-websites-webtech"}>
            <InfoText title={Texts.simpleWebsitesTitle} description={Texts.simpleWebsitesDescription}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>


        <FadeInSection>
          <ContactForm/>
        </FadeInSection>


        <FadeInSection>
          <div>
            <h1 className={"container"} id={"page-websites-webtech-title"}>{Texts.pricesTitle}</h1>
            <Prices content={PricesData}/>
          </div>
        </FadeInSection>


      </div>
    )
  }
}

export default PageWebsites;
