import {useState} from "react";

const OptionSwitch = (props) => {
  const optionA = props.optionA;
  const optionB = props.optionB;

  const textA = props.textA;
  const textB = props.textB;

  const offerB = props.offerB;
  const offerA = props.offerA;

  let [switched, setSwitched] = useState(false);

  const classNameDeactivated = "option-switch-switch-option";
  const classNameActivated = "option-switch-switch-option option-switch-switch-option-activated";

  const classNameA = switched ? classNameDeactivated : classNameActivated;
  const classNameB = switched ? classNameActivated : classNameDeactivated;

  return (
    <div className={"option-switch"}>
     <div className={"option-switch-switch"} onClick={() => setSwitched(!switched)}>
       <div className={classNameA}>
         {
            offerA &&
            <div className={"option-switch-switch-offer"}>
              {offerA}
            </div>
         }

         {textA}
       </div>
       <div className={classNameB}>
         {textB}
         {
            offerB &&
            <div className={"option-switch-switch-offer"}>
              {offerB}
            </div>
         }

       </div>
     </div>

      {switched &&
        <div className={"option-switch-content"}>
          {optionB}
        </div>
      }

      {!switched &&
        <div className={"option-switch-content"}>
          {optionA}
        </div>
      }

    </div>
  );
}

export default OptionSwitch;