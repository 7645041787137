import "./skillCircle.css";

const SkillCircle = (props) =>
{
  const image = props.image;
  let alt = props.alt;

  if (alt === null) {
    alt = "Alt Text"
  }



  return (
    <div className={"skill-circle"}>
      <img className={"skill-circle-image"}
        src={image}
        alt={alt}
      />
    </div>
  );
}

export default SkillCircle;
