import React from "react";
import Logo from "../../assets/images/logo_small_x1000.png";
import Dropdown from "../dropdown/Dropdown";

class Header extends React.Component
{
    render()
    {

        let dropdownLinksLeistungen = [
            {
                name:"Websites",
                href:"/websites"
            },
            {
                name:"Bilder",
                href:"/photos"
            },
            {
                name:"Marketingmaterial",
                href:"/marketing"
            }
        ]


        return(
            <header className="Header">
                <a href={"/"}>
                    <img src={Logo} alt="Logo" id="header-logo"/>
                </a>


                <div id={"header-title"}><a href={"/"}>John Dee Software</a></div>
                <div id={"header-links"}>
                    <Dropdown href={"/contact"} title={"Kontakt"}/>
                </div>
            </header>
        )
    }
}

export default Header;
