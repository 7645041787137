import Markdown from "react-markdown";
import {useState} from "react";
import { useMediaQuery } from 'react-responsive'

/*
  TODO:
  - Offload article data to JSON files (markdown path, image path, title, short description, date, author)
  - Make an article overview page
 */
function CustomImage(props) {

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  let style = {
    maxWidth: '100%'
  }

  if (isMobile) {
    style = {
      maxWidth: '90vw'
    }
  }

  return <img {...props} style={style} />
}

const PageArticles = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const path = props.path;
  const imagePath = props.image;
  const imageOffset = props.imageOffset
  let [content, setContent] = useState("");

  fetch(path).then((response) => response.text()).then((text) => setContent(text));

  const image = require("../../assets/images/" + imagePath);

  let imageContainerStyle = {
    height: "450px",
    position: "relative",
    zIndex: "-1",
    overflow: "hidden"
  }

  let imageStyle = {
    width: "100%",
    minWidth: "1300px",
    position: "fixed",
    top: imageOffset + "px"
  }

  let contentContainerStyle = {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    width: "100%"
  }

  if (isMobile) {
    //imageStyle.left = "-30%"
    imageContainerStyle.maxHeight = "450px"
    imageContainerStyle.height = "50vw"

    imageStyle.width = "100vw"
    imageStyle.minWidth = "100vw"
    imageStyle.maxHeight = "75vw"
    imageStyle.top = "0"
  }

  const contentStyle = {paddingLeft: "40px", paddingRight: "40px", marginBottom: "-35px", paddingBottom: "40px"}

  return (
    <div>
      <div className={"articles-image-container"} style={imageContainerStyle}>
        <img src={image} alt={"Artistic Background Image"} style={imageStyle}></img>
      </div>

      <div className={"articles-content-container"} style={contentContainerStyle}>
        <div className={"articles-content container"} style={contentStyle}>
          <Markdown
            components={{img:({node,...props})=><img style={{maxWidth:'70vw'}}{...props}/>}}
          >{content}</Markdown>
        </div>
      </div>

    </div>
  );
}

export default PageArticles;