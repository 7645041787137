import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './components/header/header.css';
import "./components/card/card.css";
import "./components/solid_text_box/solidTextBox.css";
import "./components/carousel/carousel.css";
import "./components/soft_icon_banner/softIconBanner.css";
import "./pages/home/Home.css";
import "./components/dropdown/dropdown.css";
import "./components/sidebar/sidebar.css";
import "./components/info_text/infoText.css";
import "./components/monthy_package/monthlyPackage.css";
import "./components/footer/footer.css";
import "./components/option_switch/optionSwitch.css";
import "./pages/impressum/impressum.css";
import "./pages/websites/pageWebsites.css";
import "./pages/flatrate/pageFlatrate.css";
import "./parallax.css";
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

