import React from "react";

class Impressum extends React.Component
{
    render()
    {
        return(
            <div id={"impressum"} className={"text-body"}>
                <div className={"container"}>
                    <h1>Impressum</h1>

                    <h2>Angaben gemäß 5 TMG</h2>
                    <p>John Dee Zimmermann<br />
                        John Dee Software<br />
                        Heinrich Engel Straße 6<br />
                        64572 Büttelborn</p>

                    <h2>Kontakt</h2>
                    <p>Telefon: 0172 / 9963590<br />
                        E-Mail: contact@johndee-software.de</p>

                    <h2>Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gemäß 27 a Umsatzsteuergesetz:<br />
                        DE348053254</p>

                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                    <h2>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </div>
            </div>
        )
    }
}

export default Impressum
