import "./checkedFeature.css";

const CheckedFeature = (props) => {

  let icon = "check";

  if (props.icon != null) icon = props.icon;

  return (
    <div className={"feature"}>
      <div className={"feature-icon"}>
        <span className="material-icons">
          {icon}
        </span>
      </div>
      <div className={"feature-title"}>
        {props.title}
      </div>
      <div className={"feature-description"}>
        {props.description}
      </div>
    </div>
  );
}


export default CheckedFeature;
