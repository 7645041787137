import React from "react";

const SoftIconBanner = (props) =>
{
  const iconName = props.icon_name;
  const text = props.text;
  const link = props.link;

  return(
    <div className={"soft-icon-banner"}>
      {link != null &&
        <a className={"soft-icon-banner-icon"} href={link} target={"_blank"}>
          <span className="material-icons">
          {iconName}
          </span>
        </a>
      }
      {link == null &&
        <div className={"soft-icon-banner-icon"}>
          <span className="material-icons">
          {iconName}
          </span>
        </div>
      }


      <a className={"soft-icon-banner-text"} href={link} target={"_blank"}>
        {text}
      </a>
    </div>
  );
}

export default SoftIconBanner;