import React from "react";

import "./prices.css";

class Prices extends React.Component
{
    constructor(props) {
        super(props)
        this.state = { mobile: window.matchMedia("(max-width: 999px)").matches };
    }

    componentDidMount() {
        const handler = e => this.setState({mobile: e.matches});
        window.matchMedia("(max-width: 999px)").addEventListener('change', handler);
    }

    render()
    {
        let content = this.props.content;

        if (this.state.mobile) return (this.renderMobile(content))

        let rows = [];

        const tiers = content.amountOfTiers;


        let tiersContent = content.tiers;
        let tierRow = [];
        tierRow.push(<td></td>)
        for (let i = 0; i<tiers; i++)
        {
            tierRow.push(<td>{tiersContent[i]}</td>);
        }

        rows.push(
            <tr className={"prices-table-toprow"}>{tierRow}</tr>
        );

        for (let i = 0; i<content.rows.length; i++)
        {
            const rowData = content.rows[i];

            let rowTitle = <td className={"prices-table-rowtitle"}>{rowData.title}</td>
            let row = [];

            row.push(rowTitle);

            const rowDataTiers = rowData.tiers;
            for (let i = 0; i<tiers; i++)
            {
                let strTier = rowDataTiers[i];

                let toAdd;

                if (strTier === "YES")
                {
                    toAdd = <span style={{color: "var(--primary)"}} className="material-icons">done</span>
                }
                else if (strTier === "NO")
                {
                    toAdd = <span className="material-icons">close</span>
                }
                else
                {
                    toAdd = <div>{strTier}</div>
                }


                row.push(<td>{toAdd}</td>);
            }

            rows.push(<tr>{row}</tr>);
        }


        return (
            <div className={"container prices"}>
                <table className={"prices-table"}>
                    {rows}
                </table>
            </div>
        )
    }

    renderMobile(content)
    {
        let tables = [];

        let amountOfTiers = content.amountOfTiers;

        for (let i = 0; i<amountOfTiers; i++)
        {
            let title = content.tiers[i];
            let rows = [];

            rows.push(<div className={"prices-title-mobile"}>{title}</div>);

            for (let j = 0; j<content.rows.length; j++)
            {
                let rowContent = content.rows[j];

                if (rowContent.excludeOnMobile != null && rowContent.excludeOnMobile) continue;

                let result = rowContent.tiers[i];

                if (result === "NO" && !rowContent.forceOnMobile) continue;

                let toAdd;

                if (result === "YES")
                {
                    toAdd = <div className={"prices-row-mobile"}>
                                <span style={{color: "var(--primary)"}} className="material-icons">done</span>
                                {rowContent.title}
                            </div>
                }
                else if (result === "NO")
                {
                    toAdd = <div className={"prices-row-mobile"}>
                                <span className="material-icons">close</span>
                                {rowContent.title}
                            </div>
                }
                else
                {
                    toAdd = <div className={"prices-row-mobile"}>
                        {rowContent.title + ": "}
                        <div className={"prices-row-mobile-information"}>&nbsp;{result}</div>
                    </div>
                }

                rows.push(toAdd)
            }

            tables.push(
                <div className={"prices-table-mobile"}>
                    {rows}
                </div>
            )

        }


        return (
            <div>
                {tables}
            </div>
        );
    }
}

export default Prices;