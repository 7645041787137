import React from "react";
import Header from "./components/header/Header";
import Home from "./pages/home/Home"
import Impressum from "./pages/impressum/Impressum";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";
import PageWebsites from "./pages/websites/PageWebsites";
import PagePhotos from "./pages/photos/PagePhotos";
import {ParallaxProvider} from "react-scroll-parallax";
import PagePrivacyContact from "./pages/privacy_contact/PagePrivacyContact";
import PagePrivacy from "./pages/privacy/PagePrivacy";
import PageContact from "./pages/contact/PageContact";
import PageMarketing from "./pages/marketing/PageMarketing";
import PageArticles from "./pages/articles/PageArticles";

import MDTest from "../src/assets/texts/markdown/test.md";
import MDBasicOverview from "../src/assets/texts/markdown/BasicOverview.md";
import ImgBasicOverview from "./assets/images/articles/basic_overview/old_computers_4x3.webp"
import PageFlatrate from "./pages/flatrate/pageFlatrate";



class App extends React.Component
{

    render()
    {
        return(
            <div className="App" id={"App"}>
                <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"}/>


                <ParallaxProvider>
                    <div id={"page-wrap"}>

                        <Header/>

                        <div id={"page"}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path={"/"} element={<Home/>}/>
                                    <Route path={"/impressum"} element={<Impressum/>}/>
                                    <Route path={"/websites"} element={<Home/>}/>
                                    <Route path={"/photos"} element={<Home/>}/>
                                    <Route path={"/flatrate"} element={<Home/>}/>
                                    <Route path={"/privacy"} element={<PagePrivacy/>}/>
                                    <Route path={"/contact"} element={<PageContact/>}/>

                                </Routes>
                            </BrowserRouter>
                        </div>


                        <Footer/>

                    </div>
                </ParallaxProvider>

            </div>
        );
    }
}

export default App;
