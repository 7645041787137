import React from "react";

class InfoText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {mobile: window.matchMedia("(max-width: 999px)").matches};
  }

  componentDidMount() {
    const handler = e => this.setState({mobile: e.matches});
    window.matchMedia("(max-width: 999px)").addEventListener('change', handler);
  }


  render() {
    const title = this.props.title;
    const description = this.props.description;

    const image = this.props.image;
    let element = this.props.element;

    let link = this.props.href;


    /*  Making the image position adjustable  */
    let position = this.props.position;
    if (position == null) position = "bottom";

    let mobilePosition = this.props.mobilePosition;
    if (mobilePosition == null) mobilePosition = "top";
    if (this.state.mobile) position = mobilePosition;

    let imageSize = this.props.imageSize;
    if (imageSize == null) imageSize = "100%"


    let columns = "";
    if (position === "left") columns = "40% 60%";
    else if (position === "right") columns = "60% 40%";
    else columns = "";

    let rows = "";
    if (position === "top") rows = "40% 60%";
    else if (position === "bottom") rows = "60% 40%";
    else rows = "";

    let style =
      {
        display: (this.state.mobile) ? "flex" : "grid",
        gridTemplateRows: rows,
        gridTemplateColumns: columns,
        flexDirection: (position === "top" || position === "bottom") ? "column" : "row",
        justifyContent: "center",
        alignItems: image == null ? "safe center" : "center",
        justifyItems: "center",
        alignContent: "center",
      };


    /*  Making the title position adjustable  */
    let titlePosition = this.props.titlePosition;
    if (titlePosition == null || this.state.mobile) titlePosition = "top";


    let titleColumns = "";
    if (titlePosition === "left") titleColumns = "30% 70%";
    else if (titlePosition === "right") titleColumns = "70% 30%";
    else titleColumns = "";

    let titleRows = "";
    if (titlePosition === "top") titleRows = "20% 80%";
    else if (titlePosition === "bottom") titleRows = "80% 20%";
    else titleRows = "";

    let styleTitleContainer =
      {
        display: "grid",
        gridTemplateRows: titleRows,
        gridTemplateColumns: titleColumns,
        justifyContent: " center",
        alignItems: "center",
        justifyItems: " center",
        alignContent: " center",

      };


    const img =
      <a href={link} target={"_blank"}>
        <img
          src={image}
          alt={"Descriptive image"}
          className={"info-text-image"}
          style={{width: imageSize}}
        />
      </a>;

    const titleElement = <h1 className={"info-text-title"}>{title}</h1>;

    return (

      <div className={"container info-text"} style={styleTitleContainer}>

        {(titlePosition === "top" || titlePosition === "left") && titleElement}

        <div className={"info-text-container"} style={style}>
          {
            image != null && (position === "top" || position === "left") &&
            img
          }

          {
            element != null && (position === "top" || position === "left") &&
            <div style={{width: imageSize}}>{element}</div>
          }


          <p
            className={"info-text-description"}
            style={this.state.mobile && image != null ? {marginTop: "10px"} : {}}
          >
            {description}
          </p>


          {
            image != null && (position === "bottom" || position === "right") &&
            img
          }

          {
            element != null && (position === "bottom" || position === "right") &&
            <div style={{width: imageSize}}>{element}</div>
          }
        </div>

        {(titlePosition === "bottom" || titlePosition === "right") && titleElement}


      </div>
    );
  }
}

export default InfoText;
