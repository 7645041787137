import React from "react";

const PagePrivacy = () =>
{
  return(
    <div className={"container text-body"}>
      <h1>Datenschutzerklärung</h1>

      <h4>Verantwortlich im Sinne der Datenschutzgesetzes:</h4>

      John Dee Zimmermann<br/>
      John Dee Software<br/>
      Heinrich-Engel-Straße 6<br/>
      64572 Büttelborn<br/>

      <h4>Datenschutz</h4>

      Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst.
      Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen
      Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.<br/><br/>

      Unserer Webseite kann selbstverständlich genutzt werden,
      ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt
      persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden,
      wird dies auf freiwilliger Basis geschehen.
      Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.
      <br/><br/>

      Datenübertragung im Internet,
      wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen.
      Der komplette Schutz der Daten ist im Internet nicht möglich.
      <br/><br/>

      <h4>Datenschutzerklärung Google Fonts</h4>

      Auf unseren Webseiten werden Funktionen des Dienstes Google Fonts verwendet,
      angeboten von Google Ireland Limited, Gordon House, Barrow Street Dublin 4, Irland.<br/>
      Wir verwenden diesen Dienst, um Schriftarten und "Icons" darzustellen.
      <br/><br/>
      Mehr Informationen dazu finden Sie hier:
      <a href={"https://policies.google.com/privacy"}>
        https://policies.google.com/privacy
      </a>


      <h4>Auskunft, Löschung, Sperrung</h4>

      Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten,
      deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung informieren
      und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen.
      Bitte nutzen Sie dafür die im Impressum angegebenen Kontaktwege.
      Für weitere Fragen zum Thema stehen wir Ihnen ebenfalls jederzeit zur Verfügung.

      <h4>Server-Log-Files</h4>

      Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten enthalten:

      <ul>
        <li>
          Browsertyp/ Browserversion
        </li>
        <li>
          Betriebssystem des Rechners
        </li>
        <li>
          Referrer URL
        </li>
        <li>
          Hostname des zugreifenden Rechners
        </li>
        <li>
          Uhrzeit der Serveranfrage
        </li>
      </ul>


      Diese Daten sind nicht personenbezogen.
      Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.
      {/*
            <h4>Cookies</h4>

            Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die Besucher einer freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden und die Ihr Browser verwendet.

             Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere Cookies bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch wiederzuerkennen.

            Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen. Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Werden Cookies deaktiviert, kann die Funktionalität unserer Website eingeschränkt sein.
*/}


      <h4>Änderung der Datenschutzbestimmungen</h4>


      Unsere Datenschutzerklärung kann in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung.

      <h4>Kontakt</h4>

      Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an contact@johndee-software.de mit dem Betreff „Datenschutz“.
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      Der Inhalt dieser Seite wurde bei datenschutzerklärungmuster.de erstellt.
    </div>
  )
}

export default PagePrivacy;
