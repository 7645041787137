import React from "react";

import Texts from "../../assets/texts/pages/flatrates.json";
import HomeTexts from "../../assets/texts/pages/home.json";
import InfoText from "../../components/info_text/InfoText";
import Prices from "../../components/prices/Prices";

import PricesData from "../../assets/packages.json";
import FadeInSection from "../../components/fade_in_section/FadeInSection";
import CheckedFeature from "../../components/checked_feature/CheckedFeature";
import ContactForm from "../../components/contact_form/ContactForm";

import ImgJohnDee from "../../assets/images/johndee.jpg";
import ImgPricingTool from "../../assets/images/pricingTool.jpg";
import ImgIVB from "../../assets/images/innovationsbuehne.png";
import ImgReact from "../../assets/images/reactjs-logo.png"
import ImgWordpress from "../../assets/images/WordPress_blue_logo.svg.png"
import ImgIonos from "../../assets/images/2560px-Ionos-by-1and1-logo.svg.png"
import SkillCircle from "../../components/skill_circle/SkillCircle";
import MonthlyPackage from "../../components/monthy_package/MonthlyPackage";
import OptionSwitch from "../../components/option_switch/OptionSwitch";
import ImgJohn from "../../assets/images/face.jpg";

class PageFlatrate extends React.Component {

  render() {
    const package1 = <MonthlyPackage content={PricesData} priceName={"monthly"}/>;
    const package2 = <MonthlyPackage content={PricesData} priceName={"yearly"}/>;
    return (
      <div id={"page-websites"}>

        <div className={"section"} id={"page-flatrate-span"}>
          <h1 id={"page-websites-span-title"}>{Texts.title}</h1>
        </div>

        <FadeInSection>
          <InfoText title={Texts.flatrateTitle} description={Texts.flatrateDescription} titlePosition={"left"}/>
        </FadeInSection>

        <FadeInSection>
          <InfoText title={Texts.everythingIncludedTitle} description={Texts.everythingIncludedDescription} titlePosition={"right"}/>
        </FadeInSection>

        <FadeInSection>
          <OptionSwitch
            optionA={package1}
            optionB={package2}
            textA={"Monatlich"}
            textB={"Jährlich"}
            offerB={"-15%"}
          />

        </FadeInSection>

        <FadeInSection>
          <ContactForm/>
        </FadeInSection>

        <FadeInSection>
          <CheckedFeature
            title={Texts.features[0].title}
            description={Texts.features[0].description}
          />
        </FadeInSection>
        <FadeInSection>
          <CheckedFeature
            title={Texts.features[1].title}
            description={Texts.features[1].description}
          />
        </FadeInSection>

        <FadeInSection>
          <div id={"about-me"} className={"container"}>
            <img src={ImgJohn} alt={"Bild von mir"}/>
            <div id={"about-me-texts"}>
              <div id={"about-me-heading"}>{HomeTexts.aboutMe.name}</div>
              <div id={"about-me-heading-lastname"}>{HomeTexts.aboutMe.lastname}</div>
              <p id={"about-me-description"}>{HomeTexts.aboutMe.description}</p>
            </div>
          </div>
        </FadeInSection>


        <FadeInSection>
          <div id={"page-flatrate-faq-whatpackage"}>
            <InfoText title={Texts.faq.whatpackage.title}
                      description={Texts.faq.whatpackage.description}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div id={"page-flatrate-faq-whatsincluded"}>
            <InfoText title={Texts.faq.whatsincluded.title}
                      description={Texts.faq.whatsincluded.description}
                      titlePosition={"right"}/>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div id={"page-flatrate-faq-additionalcosts"}>
            <InfoText title={Texts.faq.additionalcosts.title}
                      description={Texts.faq.additionalcosts.description}
                      titlePosition={"left"}/>
          </div>
        </FadeInSection>


        <FadeInSection>
          <ContactForm/>
        </FadeInSection>


      </div>
    )
  }
}

export default PageFlatrate;
