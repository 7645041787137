import React from "react";

class Footer extends React.Component
{
    render()
    {
        return(
            <footer>
                <a className={"footer-link"} href={"/"}>Home</a>
                <a className={"footer-link"} href={"/impressum"}>Impressum</a>
                <a className={"footer-link"} href={"/privacy"}>Datenschutz</a>
                <a className={"footer-link"} href={"/contact"}>Kontakt</a>
            </footer>
        )
    }
}

export default Footer;
